import ko from 'knockout';
import constants from '@/constants';
import logger from '@/Utils/logger';
import uploadRepository from '@/Repositories/uploadRepository';
import UploadTypeResolver from '@/Utils/uploadTypeResolver';

/**
 * Model to hold details about an upload. The model should be created with the serverModel parameter
 * uploads that already exist and the model should be created with the nativeBrowserFile parameter for
 * new uploads that have not yet been created on the server.
 * @param {*} [serverModel] Upload model returned from the server
 * @param {*} [nativeBrowserFile] File object for a new upload
 * @param {*} [parentItemType] Type of entity owned uploads (request or self-response)
 */
function UploadModel(serverModel, nativeBrowserFile, parentItemType) {

  const self = this;
  const nativeBrowserFileExists = !!nativeBrowserFile; // Convert to boolean
  const originalCameraName = serverModel ? serverModel.cameraName : null;
  const originalComments = serverModel ? serverModel.comments : '';

  self.type = serverModel ? serverModel.type : UploadTypeResolver.getTypeFromNativeFile(nativeBrowserFile, parentItemType);

  serverModel = serverModel || {};
  nativeBrowserFile = nativeBrowserFile || {};

  // Standard properties
  self.uploadId = serverModel.uploadId || null;
  self.requestId = serverModel.requestId || null;
  self.uploadToken = serverModel.uploadToken || null;
  self.addedTimestamp = serverModel.addedTimestamp || new Date().toUTCString();
  self.fileName = serverModel.fileName || nativeBrowserFile.name;
  self.fileSize = serverModel.fileSize || nativeBrowserFile.size;
  self.fileRelativePath = serverModel.fileRelativePath || nativeBrowserFile.webkitRelativePath;
  self.personaDisplayName = serverModel.personaDisplayName;

  self.rootFolderName = self.fileRelativePath ?
  self.fileRelativePath.substr(0, self.fileRelativePath.indexOf('/')) : null;
  self.comments = ko.observable(serverModel.comments || '');
  self.nativeBrowserFile = nativeBrowserFileExists ? nativeBrowserFile : null;

  self.uploadStatuses = constants.uploadStatuses;

  // Observables
  self.cameraName = ko.observable(serverModel.cameraName || null);
  self.statusName = ko.observable(serverModel.uploadStatusName || constants.uploadStatuses.authorising);
  self.progress = ko.observable(serverModel.uploadStatusName === constants.uploadStatuses.uploaded ? 100 : null);
  self.bytesUploaded = ko.observable(serverModel.bytesUploaded || 0);
  self.userIsCurrentlyUploading = ko.observable(false); // The user is currently actively uploading chunks

  // Computed observables
  self.hasChanged = ko.pureComputed(function () {
    return self.cameraName() !== originalCameraName || self.comments() !== originalComments;
  });

  self.isAuthorising = ko.pureComputed(function () {
    return self.statusName() === constants.uploadStatuses.authorising;
  });

  self.isFailedAuthorisation = ko.pureComputed(function () {
    return self.statusName() === constants.uploadStatuses.failedAuthorisation;
  });

  // TODO: Make the property name camera selected item
  // Observable for use as the selected item with the selectOrTextInput component. As the camera name is
  // just a string (with no Id) we just provide the camera name and subscribe to the selected item change
  // so we can update the name when changed.
  self.selectedItem = ko.observable({
    cameraName: serverModel.cameraName
  });

  self.selectedItem.subscribe(function (newValue) {
    self.cameraName(newValue ? newValue.cameraName : null);
  });

  /**
   * Populate upload Id, upload token and others once new server upload created, saved and returned
   * @param {*} serverModel
   */
  self.updateAfterServerModelCreated = function (serverModel) {

    self.uploadId = serverModel.uploadId;
    self.requestId = serverModel.requestId;
    self.uploadToken = serverModel.uploadToken;
    self.addedTimestamp = serverModel.addedTimestamp;
    self.statusName(serverModel.uploadStatusName);
  };
  self.setUploadStarted = function () {
    self.statusName(constants.uploadStatuses.uploading);
    self.progress(0);
    self.userIsCurrentlyUploading(true);
  };

  self.updateUploadProgress = function (percentage) {
    self.progress(percentage);
    const bytesUploaded = self.fileSize * percentage * 0.01;
    self.bytesUploaded(bytesUploaded);
  };

  self.setUploadComplete = () => {
    return uploadRepository.uploadComplete(self.uploadToken)
        .then(resultDto => {
          if (resultDto.uploadStatusName === constants.uploadStatuses.uploaded) {
            self.statusName(constants.uploadStatuses.uploaded);
            self.progress(100);
            self.bytesUploaded(self.fileSize);
            self.userIsCurrentlyUploading(false);
            return;
          }
          logger.error('FailedToCompleteUpload');
          self.statusName(constants.uploadStatuses.failed);
        });
  };

  self.setUploadFailed = function () {
    self.statusName(constants.uploadStatuses.failed);
    self.userIsCurrentlyUploading(false);
  };

  self.setUploadFailedAuthorisation = function () {
    self.statusName(constants.uploadStatuses.failedAuthorisation);
  };

  self.cancelUpload = function () {
    self.statusName(constants.uploadStatuses.cancelled);
    self.userIsCurrentlyUploading(false);
  };
}

export default UploadModel;
