import ko from 'knockout';
import repositoryHelper from './repositoryHelper';
import anonymousRepositoryHelper from './anonymousRepositoryHelper';
import UploadModel from '../Models/uploadModel';
import uploadManager from '../Utils/uploadManager';
import constants from '../constants';
import moment from 'moment';
import UploadTypeResolver from '@/Utils/uploadTypeResolver';
import { UploadParentItemType } from '@/Types/Enums/UploadParentItemType';

function UploadRepository() {

  const self = this;
  const baseResourceUrl = 'api/upload/';

  /**
   * Gets a list of uploads for a given request Id. The uploads are retrieved from the server and either
   * returned directly or used to lookup active / pending uploads from the upload manager which are
   * returned instead.
   * @param {string} requestId
   * @return {UploadModel[]}
   */
  self.getUploadsByRequestId = function (requestId) {

    const resourceUrl = baseResourceUrl + '?requestId=' + requestId;

    return new Promise(function (resolve, reject) {

      repositoryHelper.ajaxGet(resourceUrl)
          .then(function (serverUploads) {
            const uploads = createUploadModels(serverUploads);
            resolve(uploads);
          })
          .catch(function (jqXhr) {
            reject(jqXhr);
          });
    });
  };

  /**
 * Gets a list of uploads for a given response Id. The uploads are retrieved from the server and either
 * returned directly or used to lookup active / pending uploads from the upload manager which are
 * returned instead.
 * @param {string} responseId
 * @return {UploadModel[]}
 */
  self.getUploadsByResponseId = function (responseId) {

    const resourceUrl = `${baseResourceUrl}response/?responseId=${responseId}`;

    return new Promise(function (resolve, reject) {

      repositoryHelper.ajaxGet(resourceUrl)
          .then(function (serverUploads) {
            const uploads = createUploadModels(serverUploads);
            resolve(uploads);
          })
          .catch(function (jqXhr) {
            reject(jqXhr);
          });
    });
  };

  /**
   * Adds one or more files for upload. The newly created uploads are returned immediately. Asynchronously,
   * server calls are made to create the new uploads / generate upload tokens and then the upload engine
   * used to start the uploads.
   * @param {object} fileList An array of native browser File objects to be uploaded
   * @param {string} parentItemId Id of the request or self response uploads related to
   * @param {string} parentItemType Type of the item uploads related to. 'request' or 'selfResponse'.
   * @param {Function} addUploadsFailedCallback Callback to be called with an error on failure
   * @return {Array}
   */
  self.addUploads = function (fileList, parentItemId, parentItemType, addUploadsFailedCallback) {

    let i;
    let file;
    let upload;
    const serverUpload = null;
    const files = [];
    const uploads = [];

    for (i = 0; i < fileList.length; i++) {
      file = fileList[i];
      files.push(file);

      // ReSharper disable once ExpressionIsAlwaysConst
      upload = new UploadModel(serverUpload, file, parentItemType);
      subscribeToFailedUploadStatusName(upload);
      uploads.push(upload);
    }

    // Asynchronously create each upload on the server / generate upload token then start the uploads
    postNewUploadsToServer(files, parentItemId, parentItemType)
        .then(function (serverUploads) {
          startUploads(uploads, serverUploads);
        })
        .catch(function (e) {

          let upload;

          for (i = 0; i < uploads.length; i++) {
            upload = uploads[i];
            upload.setUploadFailedAuthorisation();
          }

          addUploadsFailedCallback(e);
        });

    return uploads;
  };

  /**
   * Deletes an upload by making a call to the service and cancels an active or pending upload
   * @param {UploadModel} upload
   * @return {Promise}
   */
  self.deleteUpload = function (upload) {

    const resourceUrl = baseResourceUrl + 'deleteItem/' + upload.uploadId;

    if (upload.statusName() === constants.uploadStatuses.pending ||
      upload.statusName() === constants.uploadStatuses.uploading) {

      uploadManager.cancelUpload(upload);
    }

    // If the upload failed authorisation then there is no upload to delete so return with a resolved
    // promise. For uploads that are authorising the user can't remove the upload.
    if (upload.statusName() === constants.uploadStatuses.failedAuthorisation) {
      return Promise.resolve(null);
    }

    return repositoryHelper.ajaxDelete(resourceUrl);
  };

  /**
   * Deletes an uploads by making a call to the service and cancels an active or pending uploads
   * @param {UploadModel[]} uploads
   * @return {Promise}
   */
  self.deleteUploads = function (uploads) {

    const resourceUrl = baseResourceUrl + 'deleteItems';

    ko.utils.arrayForEach(uploads, function (upload) {
      if (upload.statusName() === constants.uploadStatuses.pending ||
        upload.statusName() === constants.uploadStatuses.uploading) {

        uploadManager.cancelUpload(upload);
      }
    });

    // If the upload failed authorisation then there is no upload to delete.
    const uploadsForDelete = ko.utils.arrayFilter(uploads, function (upload) {
      return upload.statusName() !== constants.uploadStatuses.failedAuthorisation;
    }).map(function (upload) {
      return upload.uploadId;
    });

    // If there is no upload to delete so return with a resolved promise.
    // For uploads that are authorising the user can't remove the upload.
    if (uploadsForDelete.length === 0) {
      return Promise.resolve(null);
    }

    return repositoryHelper.ajaxPost(resourceUrl, uploadsForDelete);
  };

  /**
   * Takes an array of uploads and updates each via the server if the camera name has been changed
   * @param {UploadModel[]} uploads
   * @return {Promise}
   */
  self.updateUploads = function (uploads) {

    const updatedUploads = [];

    for (let i = 0; i < uploads.length; i++) {
      const upload = uploads[i];

      if (!upload.hasChanged()) {
        continue;
      }
      updatedUploads.push(ko.toJS(upload));
    }

    if (updatedUploads.length === 0) {
      return Promise.resolve();
    }

    const resourceUrl = baseResourceUrl + 'updateItems';

    return repositoryHelper.ajaxPut(resourceUrl, updatedUploads);
  };

  self.setUploadFailed = uploadToken => {
    const uri = baseResourceUrl + `setFailed/${uploadToken}`;
    return repositoryHelper.ajaxPut(uri);
  };

  /**
   * Anonymously comletes an upload on the server
   * @param {string} uploadToken
   */

  self.uploadComplete = uploadToken => {
    const resourceUrl = `${baseResourceUrl}complete/${uploadToken}`;
    return anonymousRepositoryHelper.ajaxPut(resourceUrl);
  };

  self.getUploadMetadataAndConfigurableFields = (model, uploadParentItemType) => {
    const parentItemType = uploadParentItemType === UploadParentItemType.selfResponse ?
      'response' : 'request';
    const resourceUrl = `${baseResourceUrl}${parentItemType}/metadata`;
    return repositoryHelper.ajaxPost(resourceUrl, model);
  };

  self.saveUploadsMetadata = (model, uploadParentItemType) => {
    const parentItemType = uploadParentItemType === UploadParentItemType.selfResponse ?
      'response' : 'request';
    const resourceUrl = `${baseResourceUrl}${parentItemType}/metadata`;
    return repositoryHelper.ajaxPut(resourceUrl, model);
  };

  /**
   * Start each upload after all entities created / upload tokens generated on the server
   * @param {UploadModel[]} uploads
   * @param {*} serverUploads Array of server models including upload token property
   */
  function startUploads(uploads, serverUploads) {

    let serverUpload;
    let upload;

    for (let i = 0; i < serverUploads.length; i++) {
      serverUpload = serverUploads[i];
      upload = uploads[i];

      upload.updateAfterServerModelCreated(serverUpload);
      uploadManager.addUpload(upload);
    }
  }

  function postNewUploadsToServer(files, requestId, uploadParentItemType) {
    const parentItemType = uploadParentItemType === UploadParentItemType.selfResponse ?
      'response' : 'request';
    const resourceUrl = baseResourceUrl + 'uploadItems/' + parentItemType;

    const data = {
      parentItemId: requestId,
      uploads: files.map(function (file) {
        return {
          fileName: file.name,
          fileSize: file.size,
          lastWriteDate: moment(file.lastModified || file.lastModifiedDate).toISOString(),
          fileRelativePath: file.webkitRelativePath,
          type: UploadTypeResolver.getTypeFromNativeFile(file, uploadParentItemType)
        };
      })
    };

    return repositoryHelper.ajaxPost(resourceUrl, data);
  }

  function createUploadModels(serverUploads) {

    let serverUpload;
    const uploads = [];

    for (let i = 0; i < serverUploads.length; i++) {
      serverUpload = serverUploads[i];
      uploads.push(getOrCreateUploadModel(serverUpload));
    }

    return uploads;
  }

  function getOrCreateUploadModel(serverUpload) {
    const upload = uploadManager.getUpload(serverUpload.uploadId);
    return upload ? upload : new UploadModel(serverUpload);
  }
  function subscribeToFailedUploadStatusName(upload) {
    upload.statusName.subscribe(value => {
      if (value === constants.uploadStatuses.failed) {
        self.setUploadFailed(upload.uploadToken);
      }
    });
  }
}

export default new UploadRepository();
